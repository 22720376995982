<template>
  <div>
    <b-modal
      id="accept-all-order"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="`${$t('AcceptOrders')}`"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <div class="formGroup">
        <div
          v-for="(item, index) in orderItemIds"
          :key="index"
          class="orderItems"
        >
          <div style="display:flex;justify-content: space-between;">
            <span>
              {{ item.orderedArticleNumber }}
            </span>
            <div style="display:flex; gap:10px;">
              <b-icon-x-circle
                style="cursor:pointer;"
                @click="removeArticle(index)"
              />
              <b-icon-chevron-down
                v-if="!item.isCollapsed"
                style="cursor:pointer; margin-left: 10px;"
                @click="toggleCollapse(index)"
              />
              <b-icon-chevron-up
                v-if="item.isCollapsed"
                style="cursor:pointer; margin-left: 10px;"
                @click="toggleCollapse(index)"
              />
            </div>
          </div>
          <b-collapse
            :id="'collapse-' + index"
            :visible="item.isCollapsed"
            class="mt-2"
          >
            <div
              class="formGroup"
            >
              <b-form-group
                :label="$t('Quantity')"
              >
                <b-form-input
                  v-model.number="item.quantity"
                  type="number"
                  disabled
                  :placeholder="$t('Quantity')"
                />
              </b-form-group>
              <b-form-group
                :label="$t('pickedUpQuantity')"
              >
                <b-form-input
                  v-model.number="item.pickedUpQuantity"
                  type="number"
                  disabled
                  :placeholder="$t('pickedUpQuantity')"
                />
              </b-form-group>
              <b-form-group
                :label="$t('NewQuantityToPick')"
              >
                <b-form-input
                  v-model.number="item.newQuantity"
                  type="number"
                  :placeholder="$t('Quantity')"
                />
              </b-form-group>
              <b-form-group
                :label="$t('ScheduledPickupDate')"
              >
                <date-picker
                  v-model="item.scheduledPickupDate"
                  :placeholder="$t('PickupDate')"
                  format="YYYY-MM-DD"
                  value-type="format"
                  style="width:100%;"
                  disabled
                />
              </b-form-group>
              <b-form-group
                :label="$t('PickupDate')"
              >
                <date-picker
                  v-model="item.newPickedUpDate"
                  :placeholder="$t('PickupDate')"
                  format="YYYY-MM-DD"
                  value-type="format"
                  :clearable="false"
                  style="width:100%;"
                />
              </b-form-group>
            </div>
          </b-collapse>
        </div>
        <div>
          <div class="formGroup">
            <b-form-group
              :label="$t('Location')"
            >
              <vue-select
                v-model="locationId"
                :options="getLocationsByStoreId"
                label="locationName"
                :reduce="(location) => location.locationId"
                :clearable="false"
                :placeholder="$t('Select')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('Price')"
            >
              <b-form-input
                v-model.number="orderDetails.price"
                type="number"
                disabled
                :placeholder="$t('Price')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('paidAmount')"
            >
              <b-form-input
                v-model.number="orderDetails.paidAmount"
                type="number"
                disabled
                :placeholder="$t('paidAmount')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('newPaidAmount')"
            >
              <b-form-input
                v-model.number="newPaidAmount"
                type="number"
                :placeholder="$t('newPaidAmount')"
              />
            </b-form-group>
            <b-form-group
              :label="$t('AccountName')"
            >
              <vue-select
                v-model="accountId"
                :options="getAccountDetails"
                label="name"
                :reduce="(account) => account.accountId"
                :placeholder="$t('SelectAccount')"
                :disabled="newPaidAmount == 0"
              />
              <p
                v-if="newPaidAmount > 0 && !accountId"
                style="color:red;"
              >
                {{ $t('SelectAccount') }}
              </p>
            </b-form-group>
          </div>
          <b-form-group
            :label="$t('Notes')"
          >
            <b-form-textarea
              v-model.number="paidNotes"
              type="number"
              :placeholder="$t('Notes')"
            />
          </b-form-group>
        </div>
      </div>
      <p
        v-if="orderItemIds.length == 0"
        style="color:red;"
      >
        {{ $t('NoArticlesSelected') }}
      </p>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['orderItemIds', 'orderDetails'],
  data() {
    return {
      accountId: null,
      newPaidAmount: 0,
      paidNotes: null,
      locationId: null,
    }
  },
  computed: {
    ...mapGetters([
      'getAccountDetails',
      'getLocationsByStoreId',
    ]),
  },
  watch: {
    orderItemIds: {
      handler(newValue) {
        newValue.forEach((article, index) => {
          if (this.timeoutIds && this.timeoutIds[index]) {
            clearTimeout(this.timeoutIds[index]);
          }
          this.timeoutIds = this.timeoutIds || [];
          this.timeoutIds[index] = setTimeout(() => {
            if (article.newQuantity > (article.quantity - article.pickedUpQuantity) || article.newQuantity < 0 || article.newQuantity == '') {
              article.newQuantity = 0;
            }
          }, 100);
        });
      },
      deep: true,
    },
    newPaidAmount(value) {
      if (this.timeoutIds) {
        clearTimeout(this.timeoutIds);
      }
      this.timeoutIds = setTimeout(() => {
        if (value < 0 || value == '') {
          this.newPaidAmount = 0;
        }
      }, 100);
    },
  },
  methods: {
    toggleCollapse(index) {
      this.orderItemIds[index].isCollapsed = !this.orderItemIds[index].isCollapsed;
    },
    removeArticle(index) {
      this.orderItemIds.splice(index, 1);
      this.$emit('removeBool')
    },
    onSubmit() {
      if (this.newPaidAmount > 0 && !this.accountId) {
        return
      }
      if (this.orderItemIds.length == 0) {
        return
      }
      const object = {
        orderId: this.orderDetails.orderId,
        paidAmount: this.newPaidAmount,
        paidNotes: this.paidNotes,
        currency: '€',
        locationId: '0f7d13bd-8319-ee11-abf3-124abb807f69',
        accountId: this.accountId,
        items: this.orderItemIds.map((item) => {
          return {
            orderItemId: item.orderItemId,
            pickedUpQuantity: item.newQuantity,
            pickedUpDate: item.newPickedUpDate,
          }
        }),
      }
      this.$emit('onAcceptOrderAll', object)
      this.resetForm()
    },
    onCancel() {
      this.resetForm()
    },
    resetForm() {
      this.$refs.modal.hide()
      this.newPaidAmount = 0
      this.locationId = null
      this.paidNotes = null
      this.accountId = null
    },
  },
}
</script>

    <style lang="scss" scoped>
    .formGroup {
      display: grid;
       grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    .orderItems {
        padding:10px
        ;border:1px solid lightgrey;
        border-radius:12px;
        margin-top: 10px;
    }
    </style>
