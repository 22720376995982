<template>
  <div>
    <b-modal
      id="accept-one-order"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="`${$t('AcceptOrder')} : ${acceptedOnerOrder?.orderedArticleNumber}`"
      hide-footer
      size="lg"
      @close="onCancel"
    >
      <div class="formGroup">
        <b-form-group
          :label="$t('Quantity')"
        >
          <b-form-input
            v-model.number="quantity"
            type="number"
            disabled
            :placeholder="$t('Quantity')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('pickedUpQuantity')"
        >
          <b-form-input
            v-model.number="pickedUpQuantity"
            type="number"
            disabled
            :placeholder="$t('pickedUpQuantity')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('NewQuantityToPick')"
        >
          <b-form-input
            v-model.number="newQuantity"
            type="number"
            :placeholder="$t('Quantity')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Location')"
        >
          <vue-select
            v-model="locationId"
            :options="getLocationsByStoreId"
            label="locationName"
            :reduce="(location) => location.locationId"
            :clearable="false"
            :placeholder="$t('Select')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('ScheduledPickupDate')"
        >
          <date-picker
            v-model="scheduledPickupDate"
            :placeholder="$t('PickupDate')"
            format="YYYY-MM-DD"
            value-type="format"
            style="width:100%;"
            disabled
          />
        </b-form-group>
        <b-form-group
          :label="$t('PickupDate')"
        >
          <date-picker
            v-model="pickedUpDate"
            :placeholder="$t('PickupDate')"
            format="YYYY-MM-DD"
            value-type="format"
            :clearable="false"
            style="width:100%;"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Price')"
        >
          <b-form-input
            v-model.number="price"
            type="number"
            disabled
            :placeholder="$t('Price')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('paidAmount')"
        >
          <b-form-input
            v-model.number="paidAmount"
            type="number"
            disabled
            :placeholder="$t('paidAmount')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('newPaidAmount')"
        >
          <b-form-input
            v-model.number="newPaidAmount"
            type="number"
            :placeholder="$t('newPaidAmount')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('AccountName')"
        >
          <vue-select
            v-model="accountId"
            :options="getAccountDetails"
            label="name"
            :reduce="(account) => account.accountId"
            :placeholder="$t('SelectAccount')"
            :disabled="newPaidAmount == 0"
          />
          <p
            v-if="newPaidAmount > 0 && !accountId"
            style="color:red;"
          >
            {{ $t('SelectAccount') }}
          </p>
        </b-form-group>
      </div>
      <b-form-group
        :label="$t('Notes')"
      >
        <b-form-textarea
          v-model.number="paidNotes"
          type="number"
          :placeholder="$t('Notes')"
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker,
  },
  props: ['acceptedOnerOrder'],
  data() {
    return {
      accountId: null,
      quantity: 0,
      pickedUpQuantity: 0,
      scheduledPickupDate: null,
      newQuantity: 0,
      paidAmount: 0,
      newPaidAmount: 0,
      price: 0,
      paidNotes: null,
      locationId: null,
      pickedUpDate: moment().format('YYYY-MM-DD'),
    }
  },
  computed: {
    ...mapGetters([
      'getAccountDetails',
      'getLocationsByStoreId',
    ]),
  },
  watch: {
    acceptedOnerOrder(value) {
      this.quantity = value.quantity
      this.pickedUpQuantity = value.pickedUpQuantity
      this.price = value.price
      this.paidAmount = value.paidAmount
      const pickedUpDate = value.pickedUpDate;
      const scheduledPickupDate = value.scheduledPickupDate;
      if (pickedUpDate === scheduledPickupDate) {
        this.scheduledPickupDate = moment(pickedUpDate).format('YYYY-MM-DD');
      } else {
        this.scheduledPickupDate = moment(scheduledPickupDate).format('YYYY-MM-DD');
      }
    },
    newQuantity(value) {
      if (this.timeoutIds) {
        clearTimeout(this.timeoutIds);
      }
      this.timeoutIds = setTimeout(() => {
        if (value > (this.quantity - this.pickedUpQuantity) || value < 0 || value == '') {
          this.newQuantity = 0
        }
      }, 100);
    },
    newPaidAmount(value) {
      if (this.timeoutIds) {
        clearTimeout(this.timeoutIds);
      }
      this.timeoutIds = setTimeout(() => {
        if (value < 0 || value == '') {
          this.newPaidAmount = 0;
        }
      }, 100);
    },
  },
  methods: {
    onSubmit() {
      if (this.newPaidAmount > 0 && !this.accountId) {
        return
      }
      const object = {
        orderId: this.acceptedOnerOrder.orderId,
        paidAmount: this.newPaidAmount,
        paidNotes: this.paidNotes,
        currency: '€',
        locationId: '0f7d13bd-8319-ee11-abf3-124abb807f69',
        accountId: this.accountId,
        items: [
          {
            orderItemId: this.acceptedOnerOrder.orderItemId,
            pickedUpQuantity: this.newQuantity,
            pickedUpDate: this.pickedUpDate,
          },
        ],
      }
      this.$emit('onAcceptOrder', object)
      this.resetForm()
    },
    onCancel() {
      this.resetForm()
    },
    resetForm() {
      this.$refs.modal.hide()
      this.newQuantity = 0
      this.newPaidAmount = 0
      this.locationId = null
      this.pickedUpDate = moment().format('YYYY-MM-DD')
      this.paidNotes = null
      this.accountId = null
    },
  },
}
</script>

  <style lang="scss" scoped>
  .formGroup {
    display: grid;
     grid-template-columns: 1fr 1fr;
      gap: 10px;
  }
  </style>
