<template>
  <div class="main">
    <b-tabs
      v-model="tabIndex"
      style="width: 100%"
    >
      <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Products") }}</strong>
        </template>
          <OrdersOverviewDeliveredPartially />

      </b-tab>
      <!-- <b-tab>
        <template #title>
          <strong style="font-weight: 500; color: black">{{ $t("Materials") }}</strong>
        </template>
        <div style="display: flex;gap:10px;padding-top: 20px">
          <ProductsInStock />
        </div>
      </b-tab> -->
    </b-tabs>

  </div>
</template>

<script>
import OrdersOverviewDeliveredPartially from '@/components/OrdersPartially/OrdersOverviewDeliveredPartially.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    OrdersOverviewDeliveredPartially,
  },
  data() {
    return {
      tabIndex: null,
    }
  },
  methods: {
    ...mapActions([]),
  },
}

</script>

  <style>
  .main {
    display: flex;
    gap: 10px;
  }
  </style>
