<template>
  <div>
    <b-sidebar
      id="order-details-id"
      ref="modal"
      header-class="headerModalToggle"
      right
      shadow
      size="l"
      width="70%"
      :title="$t('OrderItems')"
      no-close-on-esc
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ $t('OrderItems') }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;height: 60px;"
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div
        style="padding:20px;"
      >
        <div style="display:flex;justify-content: space-between;">
          <button
            v-b-modal.accept-all-order
            class="button"
          >
            {{ $t("Accept") }}
          </button>
          <button
            v-b-modal.payments-only
            class="button"
          >
            {{ $t("PaymentOnly") }}
          </button>
        </div>
        <table
          class="team_table"
          style="margin-top:10px;"
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  :checked="selectAll"
                  @change="toggleSelectAll"
                >
              </th>
              <th>
                {{ $t('No') }}
              </th>
              <th>
                {{ $t('Barcode') }}
              </th>
              <th>
                {{ $t('ArticleNumber') }}
              </th>
              <th>
                {{ $t('Quantity') }}
              </th>
              <th>
                {{ $t('pickedUpQuantity') }}
              </th>
              <th>
                {{ $t('Price') }}
              </th>
              <th>
                {{ $t('Discount') }}
              </th>
              <th>
                {{ $t('PriceWithDiscount') }}
              </th>
              <th>
                {{ $t('State') }}
              </th>
              <th>
                {{ $t('PickupDate') }}
              </th>
              <th>
                {{ $t('Accept') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in getOrderItemsByOrderId"
              :key="index"
            >
              <td>
                <input
                  type="checkbox"
                  :value="item.orderItemId"
                  :checked="isItemChecked(item)"
                  @change="event => toggleOrderItem(event, index)"
                >
              </td>
              <td>
                {{ index + 1 }}.
              </td>
              <td>
                {{ item.barcode }}
              </td>
              <td>
                {{ item.orderedArticleNumber }}
              </td>
              <td>
                {{ item.quantity }}
              </td>
              <td>
                {{ item.pickedUpQuantity }}
              </td>
              <td>
                {{ item.price }}
              </td>
              <td>
                {{ item.discount }}
              </td>
              <td>
                {{ item.priceWithDiscount }}
              </td>
              <td>
                {{ item.state }}
              </td>
              <td>
                <span>{{ modifyDate(item) }}</span>
              </td>
              <td>
                <button
                  v-b-modal.accept-one-order
                  class="button"
                  type="button"
                  @click="onAcceptOrder(item)"
                >
                  {{ $t('Accept') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AcceptOneOrder
        :accepted-oner-order="acceptedOnerOrder"
        @onAcceptOrder="onAcceptOrders"
      />
      <AcceptAllArticles
        :order-item-ids="orderItemIds"
        :order-details="orderDetails"
        @removeBool="removeBoolArticle"
        @onAcceptOrderAll="onAcceptOrdersAll"
      />
      <PaymentOnly
        :order-details="orderDetails"
        @acceptPayment="acceptPayments"
      />
    </b-sidebar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment'
import AcceptOneOrder from '@/components/OrdersPartially/AcceptOneOrder.vue';
import AcceptAllArticles from '@/components/OrdersPartially/AcceptAllArticles.vue';
import PaymentOnly from '@/components/OrdersPartially/PaymentOnly.vue';



export default {
  components: {
    AcceptOneOrder,
    AcceptAllArticles,
    PaymentOnly,
  },
  props: ['orderDetails'],
  data() {
    return {
      acceptedOnerOrder: {},
      orderItemIds: [],
      selectAll: false,
    };
  },
  computed: {
    ...mapGetters(['getOrderItemsByOrderId']),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['loadOrderItemsByOrderId', 'sellPartialOrders', 'procesOrderPayment']),
    acceptPayments(value) {
      this.procesOrderPayment({
        object: value,
        successCallback: () => {
          this.orderDetails.paidAmount += value.paidAmount;
        },
      })
    },
    removeBoolArticle() {
      this.selectAll = false
    },
    toggleOrderItem(event, index) {
      const item = this.getOrderItemsByOrderId[index];
      const pickedUpDate = item.pickedUpDate;
      const scheduledPickupDate = item.scheduledPickupDate;
      if (pickedUpDate === scheduledPickupDate) {
        item.scheduledPickupDate = moment(pickedUpDate).format('YYYY-MM-DD');
      } else {
        item.scheduledPickupDate = moment(scheduledPickupDate).format('YYYY-MM-DD');
      }
      const itemObject = {
        orderItemId: item.orderItemId,
        orderedProductId: item.orderedProductId,
        orderedArticleNumber: item.orderedArticleNumber,
        pickedUpQuantity: item.pickedUpQuantity,
        quantity: item.quantity,
        scheduledPickupDate: item.scheduledPickupDate,
        newQuantity: 0,
        newPickedUpDate: moment().format('YYYY-MM-DD'),
        isCollapsed: true,
      };

      if (event.target.checked) {
        this.orderItemIds.push(itemObject);
      } else {
        const itemIndex = this.orderItemIds.findIndex(
          (i) => { return i.orderedProductId === item.orderedProductId && i.orderedProductId === item.orderedProductId },
        );
        if (itemIndex !== -1) {
          this.orderItemIds.splice(itemIndex, 1);
        }
      }
      this.selectAll = this.orderItemIds.length === this.getOrderItemsByOrderId.length;
    },
    toggleSelectAll(event) {
      this.selectAll = event.target.checked;

      if (this.selectAll) {
        this.orderItemIds = this.getOrderItemsByOrderId.map((item) => {
          const pickedUpDate = item.pickedUpDate;
          const scheduledPickupDate = item.scheduledPickupDate;
          if (pickedUpDate === scheduledPickupDate) {
            item.scheduledPickupDate = moment(pickedUpDate).format('YYYY-MM-DD');
          } else {
            item.scheduledPickupDate = moment(scheduledPickupDate).format('YYYY-MM-DD');
          }
          return {
            orderItemId: item.orderItemId,
            orderedProductId: item.orderedProductId,
            orderedArticleNumber: item.orderedArticleNumber,
            pickedUpQuantity: item.pickedUpQuantity,
            quantity: item.quantity,
            scheduledPickupDate: item.scheduledPickupDate,
            newQuantity: 0,
            newPickedUpDate: moment().format('YYYY-MM-DD'),
            isCollapsed: true,
          }
        });
      } else {
        this.orderItemIds = [];
      }
    },
    isItemChecked(item) {
      return this.orderItemIds.some(
        (i) => { return i.orderItemId === item.orderItemId && i.orderedProductId === item.orderedProductId },
      );
    },
    onAcceptOrders(value) {
      this.sellPartialOrders({
        object: value,
        successCallback: () => {
          this.loadOrderItemsByOrderId({
            orderId: this.orderDetails.orderId,
          })
          this.orderDetails.paidAmount += value.paidAmount;
        },
      })
    },
    onAcceptOrdersAll(value) {
      this.sellPartialOrders({
        object: value,
        successCallback: () => {
          this.loadOrderItemsByOrderId({
            orderId: this.orderDetails.orderId,
          })
          this.orderDetails.paidAmount += value.paidAmount;
          this.orderItemIds = []
          this.selectAll = false
        },
      })
    },
    onAcceptOrder(value) {
      this.acceptedOnerOrder = {
        ...value,
        ...this.orderDetails,
      }
    },
    modifyDate(item) {
      const pickedUpDate = item.pickedUpDate;
      const scheduledPickupDate = item.scheduledPickupDate;

      if (pickedUpDate === scheduledPickupDate) {
        return moment(pickedUpDate).format('YYYY-MM-DD');
      }
      return moment(scheduledPickupDate).format('YYYY-MM-DD');
    },
    onCancelToggle() {
      this.$emit('onCancelToggle');
      this.selectAll = false
      this.orderItemIds = []
      this.$refs.modal.hide();
    },
  },
};
</script>

    <style scoped>
    .team_table td {
      padding: 16px 9px !important;
    }

    </style>
