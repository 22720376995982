<template>
  <div>
    <b-modal
      id="payments-only"
      ref="modal"
      header-class="headerModal"
      no-close-on-backdrop
      centered
      :title="`${$t('Payments')}`"
      hide-footer
      size="lg"
      @close="onCancel"
    >
      <div class="formGroup">
        <b-form-group
          :label="$t('Price')"
        >
          <b-form-input
            v-model.number="orderDetails.price"
            type="number"
            disabled
            :placeholder="$t('Price')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('paidAmount')"
        >
          <b-form-input
            v-model.number="orderDetails.paidAmount"
            type="number"
            disabled
            :placeholder="$t('paidAmount')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('newPaidAmount')"
        >
          <b-form-input
            v-model.number="newPaidAmount"
            type="number"
            :placeholder="$t('newPaidAmount')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('AccountName')"
        >
          <vue-select
            v-model="accountId"
            :options="getAccountDetails"
            label="name"
            :reduce="(account) => account.accountId"
            :placeholder="$t('SelectAccount')"
            :disabled="newPaidAmount == 0"
          />
          <p
            v-if="newPaidAmount > 0 && !accountId"
            style="color:red;"
          >
            {{ $t('SelectAccount') }}
          </p>
        </b-form-group>
      </div>
      <b-form-group
        :label="$t('Notes')"
      >
        <b-form-textarea
          v-model.number="paidNotes"
          type="number"
          :placeholder="$t('Notes')"
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <button
          type="button"
          variant="none"
          style="margin-left: 0px;"
          class="buttonSubmit"
          :disabled="newPaidAmount == 0"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </button>
        <b-button
          type="reset"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px; background: white; color: black; border-color: white;font-weight: 400;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: ['orderDetails'],
  data() {
    return {
      accountId: null,
      paidAmount: 0,
      newPaidAmount: 0,
      price: 0,
      paidNotes: null,
    }
  },
  computed: {
    ...mapGetters([
      'getAccountDetails',
      'getLocationsByStoreId',
    ]),
  },
  watch: {
    newPaidAmount(value) {
      if (this.timeoutIds) {
        clearTimeout(this.timeoutIds);
      }
      this.timeoutIds = setTimeout(() => {
        if (value < 0 || value == '') {
          this.newPaidAmount = 0;
        }
      }, 100);
    },
  },
  methods: {
    onSubmit() {
      if (this.newPaidAmount > 0 && !this.accountId) {
        return
      }
      const object = {
        orderId: this.orderDetails.orderId,
        paidAmount: this.newPaidAmount,
        paidNotes: this.paidNotes,
        currency: '€',
        accountId: this.accountId,
      }
      this.$emit('acceptPayment', object)
      this.resetForm()
    },
    onCancel() {
      this.resetForm()
    },
    resetForm() {
      this.$refs.modal.hide()
      this.newPaidAmount = 0
      this.paidNotes = null
      this.accountId = null
    },
  },
}
</script>

    <style lang="scss" scoped>
    .formGroup {
      display: grid;
       grid-template-columns: 1fr 1fr;
        gap: 10px;
    }
    </style>
