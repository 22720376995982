<template>
  <div>
    <div class="filtering">
      <div class="search-toggle">
        <p>{{ $t('SearchBy') }}</p>
        <div>
          <p
            :class="{ active2: toggleSearch === 'OrderNumber' }"
            @click="toggleName('OrderNumber')"
          >
            {{ $t('OrderNumber') }}
          </p>
          <p
            :class="{ active2: toggleSearch === 'ClientName' }"
            @click="toggleName('ClientName')"
          >
            {{ $t('ClientName') }}
          </p>
        </div>
      </div>
      <div
        class="searchfilter"
        style="display: flex;justify-content: space-between;"
      >
        <div
          v-if="toggleSearch === 'ClientName'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchClient == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchClient != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchClient = ''"
              />
              <input
                v-model="searchClient"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('ClientName')"
                :style="getFilteredOrdersbyCN.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChanged"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
              >
            </div>
            <div
              v-if="getFilteredOrdersbyCN.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getFilteredOrdersbyCN.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredOrdersbyCN"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByClientName(result);"
              >
                <p style="margin: 0">
                  {{ result.nameToDisplay }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="toggleSearch === 'OrderNumber'"
          style="display: flex; align-items: center"
        >
          <div>
            <div class="black">
              <b-icon-search
                v-if="searchOrderNumber == ''"
                class="search1"
                style="position: absolute; left: 5px; top: 12px"
              />
              <b-icon-x-circle
                v-else-if="searchOrderNumber != ''"
                class="search1"
                style="position: absolute; right: 20px; top: 12px; cursor: pointer"
                @click="searchOrderNumber = ''"
              />
              <input
                v-model="searchOrderNumber"
                type="text"
                autocomplete="off"
                name="search-box"
                :placeholder="$t('OrderNumber')"
                :style="getFilteredOrdersbyON.length > 0? 'border-radius: 8px 8px 0px 0px' : ''"
                @keyup="inputChangedBarcode"
                @keydown.down="onArrowDownBarcode"
                @keydown.up="onArrowUpBarcode"
              >
            </div>
            <div
              v-if="getFilteredOrdersbyON.length > 0"
              ref="scrollContainer"
              class="dropdrop"
              :style="getFilteredOrdersbyON.length > 7 ? 'height: 210px' : 'height: auto'"
            >
              <div
                v-for="(result, index) in getFilteredOrdersbyON"
                :key="index"
                ref="options"
                style="cursor: pointer"
                class="search-item"
                :class="{ 'is-active': index === arrowCounter }"
                @click="searchByOrderNumberFilter(result);"
              >
                <p style="margin: 0">
                  {{ result }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex;gap:5px;">
      <StoresDropdown
        :filiale="store"
        @updateFiliale="updateFiliale"
      />
      <b-form-group
        :label="$t('OrderType')"
        style="width:15%"
      >
        <vue-select
          v-model="selectedOrderType"
          :options="transformOrderTypes"
          label="text"
          :clearable="false"
          :placeholder="$t('Select')"
        />
      </b-form-group>
    </div>
    <div>
      <table
        class="team_table"
        style="margin-top:10px;"
      >
        <thead>
          <tr>
            <th>
              {{ $t('No') }}
            </th>
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('OrderType') }}
            </th>
            <th>
              {{ $t('ClientName') }}
            </th>
            <th>
              {{ $t('StoreName') }}
            </th>
            <th>
              {{ $t('paidAmount') }}/{{ $t('Price') }}
            </th>
            <th>
              {{ $t('PhoneNumber') }}
            </th>
            <th>
              {{ $t('ShippingAddress') }}
            </th>
            <th>
              {{ $t('Created') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in getPartiallyDeliveredOrders"
            :key="index"
            v-b-tooltip.hover
            v-b-toggle.order-details-id
            :title="$t('ClickToView')"
            style="cursor:pointer;"
            @click="loadOrderDetails(item.orderId, item)"
          >
            <td>
              {{ getTotalItemsPartiallyOrders-(pageNumber-1)*pageSize-index }}.
            </td>
            <td>
              {{ item.orderNumber }}
            </td>
            <td>
              {{ $t(item.orderType) }}
            </td>
            <td>
              {{ item.ordererResponseDto.firstName }} {{ item.ordererResponseDto.lastname }}
            </td>
            <td>
              {{ item.storeName }}
            </td>
            <td>
              {{ item.paidAmount }}€ / {{ item.price }}€
            </td>
            <td>
              +{{ countryCallingCode[item.ordererResponseDto.countryCode] }} {{ item.ordererResponseDto.telephoneNumber }}
            </td>
            <td>
              <span
                v-for="(value, key) in item.billingAddressDto"
                :key="key"
                v-b-tooltip.hover
                :title="getBillingAddressString(item.billingAddressDto)"
                style="margin-right: 10px"
              >
                <span v-if="key === 'street' || key==='houseNumber'">
                  {{ value }} ...
                </span>
              </span>
            </td>
            <td>
              <span>{{ modifyDate(item.created) }}</span>

            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <b-pagination
          v-if="getTotalItemsPartiallyOrders > 15"
          v-model="pageNumber"
          :total-rows="getTotalItemsPartiallyOrders"
          :per-page="pageSize"
          first-number
          last-number
          align="fill"
          prev-class="prev-itemi"
          next-class="next-itemi"
          class="mt-1 mb-0"
        >
          <template #prev-text>
            <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
          </template>
          <template #next-text>
            <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
          </template>
        </b-pagination>
      </div>
    </div>
    <OrderItemsByIdToggle
      :order-details="orderDetails"
      @onCancelToggle="onCancelToggle"
    />
    <div
      v-if="isSidebarOpen"
      class="backdrop"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { countryCallingCodes } from '@/countryCodes'
import moment from 'moment'
import OrderItemsByIdToggle from './OrderItemsByIdToggle.vue';
import StoresDropdown from '../StoresDropdown.vue';


export default {
  components: {
    OrderItemsByIdToggle,
    StoresDropdown,
  },
  props: [],
  data() {
    return {
      orderDetails: null,
      isSidebarOpen: false,
      pageNumber: 1,
      pageSize: 15,
      countryCallingCode: countryCallingCodes,
      store: { storeId: null, storeName: 'No filiale', storeGLN: null },
      selectedOrderType: { orderType: null, text: 'All' },
      toggleSearch: 'OrderNumber',
      searchClient: '',
      searchOrderNumber: '',
      searchOrderNumberId: null,
      searchClientId: null,
      arrowCounter: 0,
      enterPressed: false,
      skipWatch: false,
    };
  },
  computed: {
    ...mapGetters([
      'getTotalItemsPartiallyOrders',
      'getPartiallyDeliveredOrders',
      'getFilteredOrdersbyCN',
      'getFilteredOrdersbyON',
      'getAllStoresDropdown',
      'getOrderTypes',
    ]),
    transformOrderTypes() {
      const allOption = { value: null, text: this.$t('All'), orderType: 'all' };
      const orderTypes = this.getOrderTypes.map((order) => {
        return {
          value: order.orderType,
          text: this.$t(order.orderType),
          orderType: order.orderType,
        };
      });
      const websiteOrderType = {
        value: 'Website',
        text: 'Website',
        orderType: 'Website',
      };
      return [allOption, ...orderTypes, websiteOrderType];
    },
  },
  watch: {
    pageNumber(value) {
      if (value) {
        this.loadPartiallyOrders({
          storeId: this.store.storeId,
          clientId: this.searchClientId,
          orderNumber: this.searchOrderNumberId,
          salesType: 'Product',
          orderType: this.selectedOrderType.orderType,
          pageNumber: value,
          pageSize: this.pageSize,
        });
      }
    },
    selectedOrderType(value) {
      if (value) {
        this.loadPartiallyOrders({
          storeId: this.store.storeId,
          clientId: this.searchClientId,
          orderNumber: this.searchOrderNumberId,
          salesType: 'Product',
          orderType: value.orderType,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }
    },
    store(value) {
      if (value) {
        this.loadPartiallyOrders({
          storeId: value.storeId,
          clientId: this.searchClientId,
          orderNumber: this.searchOrderNumberId,
          salesType: 'Product',
          orderType: this.selectedOrderType.orderType,
          pageNumber: this.pageNumber,
          pageSize: this.pageSize,
        });
      }
    },
    searchClient(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.searchByCustomerName(value);
        }
      }, 500);
    },
    searchOrderNumber(value) {
      if (this.skipWatch) {
        this.skipWatch = false;
        return;
      }

      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
        } else if (!this.enterPressed) {
          this.searchByOrderNumber({
            orderNumber: value,
            salesType: 'Product',
          });
        }
      }, 500);
    },
  },
  mounted() {
    this.loadPartiallyOrders({
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    });
    this.loadAllStoresByDropdown({
      amountOfRecords: 100,
    })
    this.loadOrderTypes();
    this.accountDetails(
      {
        isActive: true,
        bankId: null,
      },
    )
  },
  methods: {
    ...mapActions([
      'loadPartiallyOrders',
      'loadAllStoresByDropdown',
      'searchByOrderNumber',
      'searchByCustomerName',
      'resetOrdersNumbers',
      'resetClientName',
      'loadOrderTypes',
      'loadOrderItemsByOrderId',
      'accountDetails',
      'loadLocationsByStoreId',
    ]),
    updateFiliale(newFiliale) {
      this.store = newFiliale;
    },
    loadOrderDetails(value, item) {
      this.isSidebarOpen = true
      this.orderDetails = {
        orderId: item.orderId,
        price: item.price,
        paidAmount: item.paidAmount,
      }
      this.loadOrderItemsByOrderId({
        orderId: value,
      })
      // getLocationsByStoreId
      this.loadLocationsByStoreId({
        object: [item.storeId],
      })
    },
    onCancelToggle() {
      this.isSidebarOpen = false
      this.loadPartiallyOrders({
        storeId: this.store.storeId,
        clientId: this.searchClientId,
        orderNumber: this.searchOrderNumberId,
        salesType: 'Product',
        orderType: this.selectedOrderType.orderType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      });
    },
    getBillingAddressString(billingAddressDto) {
      return Object.values(billingAddressDto).join(' ');
    },
    modifyDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },
    toggleName(value) {
      this.toggleSearch = value;
      this.searchClient = '';
      this.searchOrderNumber = '';
      this.searchOrderNumberId = null;
      this.searchClientId = null;
      this.removeLists();
    },
    async searchByClientName(value) {
      this.loadPartiallyOrders({
        storeId: this.store.storeId,
        clientId: value.clientId,
        orderNumber: null,
        salesType: 'Product',
        orderType: this.selectedOrderType.orderType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchClient = value.nameToDisplay;
        this.searchClientId = value.clientId;
        this.resetClientName();
      });
    },
    async searchByOrderNumberFilter(value) {
      this.loadPartiallyOrders({
        storeId: this.store.storeId,
        clientId: null,
        orderNumber: value,
        salesType: 'Product',
        orderType: this.selectedOrderType.orderType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(() => {
        this.skipWatch = true;
        this.searchOrderNumber = value;
        this.searchOrderNumberId = value;
        this.resetOrdersNumbers();
      });
    },
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyCN.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByClientName(this.getFilteredOrdersbyCN[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    onArrowDownBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getFilteredOrdersbyON.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUpBarcode(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChangedBarcode(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOrderNumberFilter(this.getFilteredOrdersbyON[this.arrowCounter])
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    removeLists() {
      this.resetClientName()
      this.resetOrdersNumbers()
      this.loadPartiallyOrders({
        storeId: this.store.storeId,
        clientId: null,
        orderNumber: null,
        salesType: 'Product',
        orderType: this.selectedOrderType.orderType,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
      this.searchOrderNumberId = null;
      this.searchClientId = null;
    },
  },
};
</script>

  <style scoped lang="scss">
    .team_table td {
      padding: 16px 9px !important;
    }
    .dropdrop {
      display: inline-block;
      overflow: auto;
      position: absolute;
      background: white;
      width: 227px;
      box-shadow: none;
      z-index:3;
    }

    input[name="search-box"] {
        display: block;
        max-width: 500px;
        width: 95%;
        padding: 0.5rem 1rem;
        padding-left: 25px;
    }
    .search-item {
        padding: 5px;
        max-width: 500px;
        width: 100%;
        font-weight: 400;
        color: #82868c;
        font-size: 1rem;
        border-bottom: 1px solid #e8e8e8;
    }
    .active2 {
      font-weight: bold;
      color: $base-color;
    }
    </style>
